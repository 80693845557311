import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AppProvider } from '../../utils/AppContext';

// Components
import Template from '../Template';
import AuthPage from '../AuthPage/AuthPage';
import Havenbezoeken from '../Havenbezoeken/Havenbezoeken';
import Havenbezoek from '../Havenbezoek/Havenbezoek';
import Schepen from '../Schepen/Schepen';
import Schip from '../Schip/Schip';
import Profiel from '../Profiel/UserProfile';
import Page from "../Page/Page";
import { withTranslation } from 'react-i18next';
import helper from "../../assets/helper/";
import Store from "../../Reducers/Store";
import Wrapper from "./Wrapper";

// This component ios HoC that prevents the user from accessing a route if he's not logged in
import PrivateRoute from '../PrivateRoute/PrivateRoute';

// Design
import '../../assets/css/template.css';
import Client from "../../Model/Client";

class App extends Component {

    constructor (props) {
        super(props);

        this.setUserInfo = this.setUserInfo.bind(this);
        let userInfo = helper.getUserInfo();

        this.state = {
            language: this.props.i18n.language,
            userInfo: userInfo,
            setUserInfo: this.setUserInfo
        };
    }

    setUserInfo(userInfo) {
        this.setState({
            userInfo: userInfo
        });
    }

    render() {
        return (
            <Client>
                <Store>
                    <Wrapper>
                        <AppProvider value={this.state}>
                            <Router>
                                <div className="App">
                                    <Template>
                                        <Switch>
                                            <PrivateRoute path="/" component={Havenbezoeken} exact />
                                            <PrivateRoute path="/schepen" component={Schepen} exact />
                                            <PrivateRoute path="/schip/nieuw" component={Schip} exact />
                                            <PrivateRoute path="/schip/:id" component={Schip} exact />
                                            <PrivateRoute path="/havenbezoek/nieuw" component={Havenbezoek} exact />
                                            <PrivateRoute path="/profiel" exact component={(props) => <Profiel {...props} onLanguageChange={this.handleLanguageChange} />} />
                                            <Route path="/auth/:authType/:id?" render={(props) => <AuthPage {...props} onLanguageChange={this.handleLanguageChange} />} />
                                            <PrivateRoute path="" component={Page} />
                                        </Switch>
                                    </Template>
                                </div>
                            </Router>
                        </AppProvider>
                    </Wrapper>
                </Store>
            </Client>
        );
    }
}

export default withTranslation()(App);
